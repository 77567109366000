import naja from 'naja';
import ModalExtension from './../controls/modal/index';
import ConfirmDialogExtension from './../controls/confirmDialog/index';
import FormsConfirmDialogExtension from './../controls/formConfirmDialog/index';
import Select2Extension from './select2';
import FlashesExtension from "./../controls/flashes";
import BootstrapExtension from "./../controls/bootstrap";
import FormsExtension from "./../controls/forms/index";
import FileUploadExtension from "../controls/fileUpload";
import BaseGridExtension from "../controls/baseGrid";
import LoadingIndicatorExtension from "../controls/loadingIndicator";
import IframeInputExtension from "../controls/IframeInput";
import AutoFillInputExtension from "./auto-fill-input";
import DependentSelect from "../controls/dependentSelect";
import WysiwygExtension from "../controls/wysiwyg";
import CheckboxFilter from "../controls/checkboxFilter";
import CheckboxSelectAll from "../controls/checkboxSelectAll";
import VisibilityDependentInputsExtension from "@/ui/visibility-dependent-inputs";
import TimeSheetExtension from "@/controls/timeSheet";
import ContentDropdownExtension from "@/controls/contentDropdown";

document.addEventListener('DOMContentLoaded', () => {
	naja.initialize({
		history: true,
	});
	naja.redirectHandler.addEventListener('redirect', (event) => event.detail.setHardRedirect(true));

	naja.registerExtension(new ModalExtension());
    const select2Extension = new Select2Extension();
    select2Extension.init();
    naja.registerExtension(select2Extension);
    naja.registerExtension(new IframeInputExtension());
    naja.registerExtension(new ConfirmDialogExtension());
    naja.registerExtension(new FormsConfirmDialogExtension());
    naja.registerExtension(new FlashesExtension());
    naja.registerExtension(new FormsExtension());
    naja.registerExtension(new FileUploadExtension());
    naja.registerExtension(new BaseGridExtension());
    naja.registerExtension(new LoadingIndicatorExtension());
    naja.registerExtension(new AutoFillInputExtension());
    naja.registerExtension(new VisibilityDependentInputsExtension());
    naja.registerExtension(new BootstrapExtension());
    naja.registerExtension(new DependentSelect());
    naja.registerExtension(new WysiwygExtension());
    naja.registerExtension(new CheckboxFilter());
    naja.registerExtension(new CheckboxSelectAll());
    naja.registerExtension(new TimeSheetExtension());
    naja.registerExtension(new ContentDropdownExtension());

    // naja.uiHandler.addEventListener('interaction', (event) => {
    //     const {element} = event.detail;
    //     const question = element.dataset.confirm;
    //     if (question && ! window.confirm(question)) {
    //         event.preventDefault();
    //     }
    // });

    naja.addEventListener('success', event => {
        if (event.detail.payload.redirectNewTabUrl) {
            window.open(event.detail.payload.redirectNewTabUrl, '_blank');
        }

        if (event.detail.payload.showModal === false) {
            $('.modal').modal('hide');
            $('.modal-backdrop').remove();
            // naja.makeRequest('GET', {link refresh!})
        }
    });
});
