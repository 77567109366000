import naja from "naja";

class ContentDropdownExtension {
    initialize(naja) {
        window.addEventListener('DOMContentLoaded', this.init.bind(this));
    }

    init()
    {
        const self = this;
        $(document).on('click', '.content-dropdown-toggle', function(e){
            e.preventDefault();
            const component = $(this).closest('.content-dropdown');
            component.toggleClass('opened');
        });
    }

}

export default ContentDropdownExtension;
