import naja from "naja";

class FormsExtension {
    initialize(naja)
    {
        window.addEventListener('DOMContentLoaded', this.reloadForm.bind(this));
        // naja.snippetHandler.addEventListener('afterUpdate', (event) => {
        //     event.detail.snippet.innerHTML = event.detail.content;
        // });
    }

    reloadForm()
    {
        $(document).on('change', '.on-change-reload-form', function(event) {
            const form = this.closest('form');
            if ($(form).hasClass('ajax'))
            {
                if ($(this).hasClass('on-change-skip-validation'))
                {
                    let values = new FormData(form);
                    values.append('onChangeSkipValidation', 'onChangeSkipValidation');
                    naja.makeRequest('POST', form.action, values).then((data) => {
                        window.Nette.init();
                    });
                } else {
                    naja.uiHandler.submitForm(form);
                }

            } else {
                $(form).submit();
            }
        });

        $(document).on('keyup', 'input[data-autocomplete-provide]', function (event) {
            const $input = $(this);
            const link = $input.attr('data-autocomplete-provide');
            const query = ($input.val() || '').trim();

            $input.attr('autocomplete', 'off');
            if (query.length < 3) {
                $('.autocomplete-box').remove();
                return;
            }

            $.ajax({
                type: 'POST',
                url: link,
                data: { query: query },
                success: function (data) {
                    $('.autocomplete-box').remove();

                    if (data.length === 0) return;

                    const $box = $('<div class="autocomplete-box"></div>').css({
                        position: 'absolute',
                        zIndex: 10000,
                        background: '#fff',
                        border: '1px solid #ccc',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        padding: 0,
                    });

                    data.forEach(item => {
                        $('<div class="autocomplete-option"></div>')
                            .text(item)
                            .css({
                                padding: '8px',
                                cursor: 'pointer',
                            })
                            .hover(
                                function () { $(this).css('background', '#f0f0f0'); },
                                function () { $(this).css('background', '#fff'); }
                            )
                            .on('mousedown', function () {
                                $input.val(item);
                            })
                            .appendTo($box);
                    });

                    $('body').append($box);

                    const offset = $input.offset();
                    $box.css({
                        top: offset.top + $input.outerHeight(),
                        left: offset.left,
                        width: $input.outerWidth(),
                    });
                },
                error: function (xhr, status, error) {
                    console.error('Error:', error);
                }
            });
        });

        $(document).on('blur', 'input[data-autocomplete-provide]', function () {
            setTimeout(() => {
                $('.autocomplete-box').remove();
            }, 100);
        });

        $(document).on('click', function (event) {
            if (!$(event.target).closest('input[data-autocomplete-provide], .autocomplete-box').length) {
                $('.autocomplete-box').remove();
            }
        });


    }
}

export default FormsExtension;
